const en = {
  contact: 'Contact',
  langDE: 'German',
  langEN: 'English',
  visitSimplifyDE: 'Visit Simplify Data in German',
  visitSimplifyEN: 'Visit Simplify Data in English',
  aboutus: 'About us',
  career: 'Career',
  partner: 'Partner',
  linuxserver: 'Linux Server',
  windowsserver: 'Windows Server',
  itsupport: 'IT support',
  socialmedianalyse: 'Social media analysis',
  companydata: 'Company data',
  ki: 'Artificial intelligence',
  wpdev: 'Wordpress development',
  wpsup: 'Wordpress support',
  services: 'Services',
  homepage: 'Homepage',
};

export default en;
