const de = {
  contact: 'Kontakt',
  langDE: 'Deutsch',
  langEN: 'English',
  visitSimplifyDE: "Simplify Data in deutscher Sprache besuchen",
  visitSimplifyEN: "Simplify Data in englischer Sprache besuchen",
  aboutus: 'Über uns',
  career: 'Karriere',
  partner: 'Partner',
  linuxserver: 'Linux Server',
  windowsserver: 'Windows Server',
  itsupport: 'IT-Support',
  socialmedianalyse: 'Social Media Analyse',
  companydata: 'Firmendaten',
  ki: 'Künstliche Intelligenz',
  wpdev: 'Wordpress Entwicklung',
  wpsup: 'Wordpress Betreuung',
  services: 'Dienstleistungen',
  homepage: 'Startseite',
};

export default de;
