import React, { Component } from 'react';

class Loader extends Component {
    
    render() {
        return (
            <div className={`preloader-area ${this.props.loading ? '' : 'preloader-deactivate'}`}>
                <div className="loader08"></div>
            </div>
        );
    }
}

export default Loader;